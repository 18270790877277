<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col v-if="mode === 'edit'" cols="12">
            <v-alert border="left" colored-border type="info" elevation="2">
              Die Aktualisierung der Veranstaltungsart beeinflusst alle
              bevorstehenden und noch nicht abgeschlossenen Veranstaltungen.
              Veranstaltungen in der Vergangenheit werden nicht angepasst.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-format-list-bulleted-type"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          v-model.trim="eventType.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model.trim="eventType.shortTitle"
                          :counter="6"
                          label="Kurzbezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model.number="eventType.sortKey"
                          label="Sortierschlüssel"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          type="number"
                          hint="Veranstaltungsarten werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model.trim="eventType.description"
                          counter
                          clearable
                          no-resize
                          label="Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="eventType.color"
                          label="Farbe wählen"
                          persistent-hint
                          :rules="[rules.required]"
                          required
                          disabled
                          outlined
                          prepend-inner-icon="mdi-palette"
                        ></v-text-field>
                        <v-color-picker
                          v-model="eventType.color"
                          :swatches="availableColors"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "event-type-editor",
  props: {
    eventType: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
    };
  },
  model: {
    prop: "eventType",
    event: "eventTypeChange",
  },
  computed: {
    eventTypeLocal: {
      get: function () {
        return this.eventType;
      },
      set: function (value) {
        this.$emit("eventTypeChange", value);
      },
    },
  },
};
</script>
