<template>
  <div>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Konfiguriere die Terminplanung für Deine Organisation an dieser Stelle."
      >
      </TippCard>
    </section>
    <section>
      <v-snackbar v-model="snackbar.model" timeout="3000">{{
        snackbar.text
      }}</v-snackbar>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-form
                v-model="form1.isValid"
                @submit.prevent="updateGeneralConfig"
              >
                <Subheader icon="mdi-cog" title="Allgemein"></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form1.isLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <event-deadline-config
                            :fristInMin.sync="
                              schedulingConfig.defaultDeadlineInMinutes
                            "
                          ></event-deadline-config>

                          <!-- <v-col cols="12">
                            <v-checkbox
                              label='Fehlende Rückmeldung nach Ablauf der Rückmeldefrist als "unentschuldigte" Teilnahme kennzeichnen'
                              hint="In der Anwesenheitsermittlung und Dienstverwaltung wird die fehlende Rückmeldung gesondert gekennzeichnet."
                              persistent-hint
                              disabled
                            >
                            </v-checkbox>
                          </v-col> -->
                          <v-col cols="12">
                            <v-checkbox
                              v-model="schedulingConfig.showAllEventsInList"
                              label="Anzeigen von allen Veranstaltungen der Organisation in Übersicht erlauben"
                              hint="Wenn diese Option deaktiviert ist, werden in der Übersicht nur Veranstaltungen angezeigt, die keine Tielnahmebeschränkung haben oder an denen der Benutzer teilnehmen darf. Über einen direkten Link zu den Veranstaltungsdetails kann die Veranstaltung weiterhin geteilt und aufgerufen werden."
                              persistent-hint
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :disabled="!form1.isValid"
                          :loading="form1.isLoading"
                          @click="updateGeneralConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>

            <v-col cols="12">
              <v-form
                v-model="form2.isValid"
                @submit.prevent="updateTemplateConfig"
              >
                <Subheader
                  icon="mdi-notebook"
                  title="Auswahlvorlagen"
                ></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form2.isLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="schedulingConfig.ppeTemplates"
                              :items="
                                $store.state.organization.activeOrganization
                                  .config.scheduling.ppeTemplates
                              "
                              label="Vorlagen für Einsatzkleidung/PSA verwalten"
                              multiple
                              outlined
                              clearable
                              deletable-chips
                              auto-select-first
                              :search-input.sync="searchPPE"
                              hide-selected
                              small-chips
                              persistent-hint
                              hint="Diese Vorlagen werden bei der Erstellung von Veranstaltungen und Abgabe von Rückmeldungen zur Auswahl angeboten."
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Neuen Eintrag eingeben und mit
                                      <kbd>enter</kbd> bestätigen.
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template></v-combobox
                            >
                          </v-col>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-text>
                                <div class="mb-2">Vorlagen für Aufgaben</div>
                                <v-card
                                  v-for="task in schedulingConfig.taskTemplates"
                                  :key="task.id"
                                  dense
                                  outlined
                                  class="my-2"
                                >
                                  <v-card-text class="pa-0">
                                    <v-container
                                      ><v-row>
                                        <v-col cols="12" md="5" sm="12">
                                          <v-text-field
                                            v-model.trim="task.icon"
                                            outlined
                                            dense
                                            label="Icon auswählen"
                                            placeholder="car"
                                            prefix="mdi-"
                                            hide-details="auto"
                                            hint="Optional: ID von Icon aus Material Design Icons Bibiliothek angeben"
                                            :prepend-inner-icon="`mdi-${task.icon}`"
                                            ><template v-slot:append-outer
                                              ><v-btn
                                                depressed
                                                small
                                                @click="openMdiIconLibrary"
                                                >Icons durchsuchen
                                                <v-icon right
                                                  >mdi-open-in-new</v-icon
                                                ></v-btn
                                              ></template
                                            ></v-text-field
                                          > </v-col
                                        ><v-col cols="12" md="5" sm="6">
                                          <v-text-field
                                            v-model.trim="task.title"
                                            outlined
                                            dense
                                            :rules="[rules.required]"
                                            hide-details="auto"
                                            label="Aufgabenbezeichnung"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" sm="6">
                                          <v-btn
                                            depressed
                                            text
                                            color="error"
                                            @click="deleteTask(task)"
                                            ><v-icon left small
                                              >mdi-delete</v-icon
                                            >Löschen</v-btn
                                          >
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                </v-card>
                                <v-btn
                                  color="success"
                                  depressed
                                  @click="addTask()"
                                  ><v-icon left small>mdi-plus</v-icon
                                  >Hinzufügen</v-btn
                                >
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :disabled="!form2.isValid"
                          :loading="form2.isLoading"
                          @click="updateTemplateConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>

            <v-col cols="12">
              <v-form
                v-model="form3.isValid"
                @submit.prevent="updateCancellationNoticeConfig"
              >
                <Subheader
                  icon="mdi-run-fast"
                  title="Abwesenheiten von Veranstaltungen"
                ></Subheader>
                <div class="mt-7">
                  <v-card :loading="loading" :disabled="form3.isLoading">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="
                                schedulingConfig.cancellation.allowNotice
                              "
                              label="Begründete Abwesenheiten von Veranstaltungen erlauben"
                              hint="Nach Ablauf der Rückmeldefrist kann eine Abmeldung erfolgen, die gesondert gekennzeichnet und hervorgehoben wird."
                              persistent-hint
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col
                            v-if="schedulingConfig.cancellation.allowNotice"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="
                                schedulingConfig.cancellation
                                  .notifyEventOrganizers
                              "
                              disabled
                              label="Veranstaltungsorganisatoren benachrichtigen"
                            ></v-checkbox>
                          </v-col>
                          <!-- <v-col
                            cols="12"
                            v-if="schedulingConfig.cancellation.allowNotice"
                          >
                            <v-autocomplete
                              v-model="
                                schedulingConfig.cancellation.notifyUserGroups
                              "
                              :items="userGroups"
                              :disabled="
                                !schedulingConfig.cancellation.allowNotice
                              "
                              chips
                              small-chips
                              deletable-chips
                              outlined
                              label="Zusätzlich zu benachrichtigende Benutzergruppen"
                              item-text="title"
                              item-value="meta.id"
                              multiple
                              clearable
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </v-col> -->
                          <v-col
                            cols="12"
                            v-if="schedulingConfig.cancellation.allowNotice"
                          >
                            <v-autocomplete
                              v-model="
                                schedulingConfig.cancellation.notifyPersons
                              "
                              :items="membershipsListFiltered"
                              :disabled="
                                !schedulingConfig.cancellation.allowNotice
                              "
                              chips
                              small-chips
                              outlined
                              deletable-chips
                              label="Zusätzlich zu benachrichtigende Personen"
                              item-text="displayName"
                              item-value="uid"
                              multiple
                              clearable
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            v-if="schedulingConfig.cancellation.allowNotice"
                            cols="12"
                          >
                            <notifications-forecast-preview-card
                              infoText="Es wird versucht, Organisatoren der Veranstaltung und berechtigte
          Personen zu benachrichtigen."
                              :notifyPersonsUids="
                                schedulingConfig.cancellation.notifyPersons
                              "
                              notificationTriggerId="scheduling_eventCancellationNoticeCreated"
                            ></notifications-forecast-preview-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- :disabled="!form3.isValid" -->
                        <v-btn
                          depressed
                          color="success"
                          :loading="form3.isLoading"
                          @click="updateCancellationNoticeConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import NotificationsForecastPreviewCard from "@/components/_systemwide/NotificationsForecastPreviewCard.vue";
import EventDeadlineConfig from "@/components/scheduling/EventDeadlineConfig.vue";
import { db } from "@/firebase";
import { SCHEDULING, ADMIN } from "@/store/modules.js";
import {
  // GET_SCHEDULING_CONFIG,
  GET_USER_LIST,
  GET_USER_GROUPS,
  UPDATE_SCHEDULING_CONFIG,
} from "@/store/action-types.js";

export default {
  name: "scheduling-config-tab-general",
  components: {
    TippCard,
    NotificationsForecastPreviewCard,
    Subheader,
    EventDeadlineConfig,
  },
  data() {
    return {
      searchPPE: "",
      loading: true,
      snackbar: {
        model: false,
        text: "",
      },
      form1: {
        isValid: false,
        isLoading: false,
      },
      form2: {
        isValid: false,
        isLoading: false,
      },
      form3: {
        isValid: false,
        isLoading: false,
      },

      tasks: [],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
      },
    };
  },
  computed: {
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));

      return userGroups;
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2
        .filter(
          // FIXME: temporary fix, to be watched
          (item) =>
            item.header || (item.user && item.user.displayName && item.user.uid)
        )
        .map((item) => {
          if (item.header) {
            // Skip headers and dividers
            return item;
          } else if (item.user && item.user.displayName && item.user.uid) {
            // Extract displayName and uid from membership object
            return {
              displayName: item.user.displayName || "",
              uid: item.user.uid,
            };
          } else {
            return {
              displayName: "",
              uid: "",
            };
          }
        });

      return step3;
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store
        .dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        })
        .then(() => {
          return this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
            organizationId: this.$route.params.organizationId,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
    addTask() {
      const taskId = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("eventTasks")
        .doc().id;
      this.schedulingConfig.taskTemplates.push({
        title: "",
        icon: "",
        id: taskId,
      });
    },
    deleteTask(task) {
      this.schedulingConfig.taskTemplates.splice(
        this.schedulingConfig.taskTemplates.indexOf(task),
        1
      );
    },

    updateGeneralConfig() {
      if (this.form1.isValid) {
        this.form1.isLoading = true;
        console.log(
          this.schedulingConfig.defaultDeadlineInMinutes,
          this.schedulingConfig.showAllEventsInList
        );
        this.$store
          .dispatch(`${SCHEDULING}${UPDATE_SCHEDULING_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlyGeneralConfig",
            defaultDeadlineInMinutes:
              this.schedulingConfig.defaultDeadlineInMinutes,
            showAllEventsInList: this.schedulingConfig.showAllEventsInList,
          })
          .then(() => {
            this.form1.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form1.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    updateTemplateConfig() {
      if (this.form2.isValid) {
        this.form2.isLoading = true;
        this.$store
          .dispatch(`${SCHEDULING}${UPDATE_SCHEDULING_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlyTemplateConfig",
            ppeTemplates: this.schedulingConfig.ppeTemplates,
            taskTemplates: this.schedulingConfig.taskTemplates,
          })
          .then(() => {
            this.form2.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form2.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    updateCancellationNoticeConfig() {
      if (this.form3.isValid) {
        this.form3.isLoading = true;
        this.$store
          .dispatch(`${SCHEDULING}${UPDATE_SCHEDULING_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlyCancellationConfig",
            cancellation: this.schedulingConfig.cancellation,
          })
          .then(() => {
            this.form3.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = "Einstellungen erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.form3.isLoading = false;
            this.snackbar.model = true;
            this.snackbar.text = error.message;
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
