export const importableEventTypes = [
  {
    title: "Einsatzdienst",
    shortTitle: "ED",
    description: "Geplanter Alarmbereitschaftsdienst",
    color: "#673AB7",
    sortKey: 1,
  },
  {
    title: "Einsatzdienst (angeordnet)",
    shortTitle: "ED-a",
    description: "Angeordneter Alarmbereitschaftsdienst",
    color: "#3F51B5",
    sortKey: 2,
  },
  {
    title: "Übungsdienst",
    shortTitle: "ÜD",
    description: "Aus- und Fortbildung",
    color: "#009688",
    sortKey: 3,
  },
  {
    title: "Arbeitsdienst",
    shortTitle: "AD",
    description:
      "Erledigung anfallender Arbeiten (z.B. Verwaltungsarbeiten, Instandsetzungen, Reparaturen, Inspektionen, Wartungen)",
    color: "#FFC107",
    sortKey: 4,
  },
  {
    title: "Überprüfung/Rezertifizierung",
    shortTitle: "Üprü/Rez",
    description:
      "Überprüfungen/Rezertifizierungen zur Aufrechterhaltung der Einsatztauglichkeit",
    color: "#00BCD4",
    sortKey: 5,
  },
  {
    title: "Gemeinschaftsfördernde Veranstaltung",
    shortTitle: "GemVer",
    description: "Teambuilding, Feiern, Feste etc.",
    color: "#8BC34A",
    sortKey: 6,
  },
  {
    title: "Wehrversammlung",
    shortTitle: "WehrV",
    description:
      "Wehrversammlung zur Abstimmung von Beschlüssen, Neuaufnahmen/Umsetzungen, Wahlen, Jugendfeuerwehr, sonstige Belange etc.",
    color: "#795548",
    sortKey: 7,
  },
  {
    title: "Besprechung der Wehrleitung",
    shortTitle: "WeL-Runde",
    description: "Besprechung der erweiterten Wehrleitung",
    color: "#607D8B",
    sortKey: 8,
  },
  {
    title: "Besprechung der Jugendfeuerwehrwarte",
    shortTitle: "JFW-Runde",
    description: "Besprechung der erweiterten Wehrleitung",
    color: "#607D8B",
    sortKey: 9,
  },
  {
    title: "Besprechung der Führungskräfte/FzF",
    shortTitle: "FzF-Runde",
    description: "Besprechung der erweiterten Wehrleitung",
    color: "#607D8B",
    sortKey: 10,
  },
  {
    title: "Besprechung der Jugendbetreunden",
    shortTitle: "ÜD",
    description: "Besprechung der erweiterten Wehrleitung",
    color: "#607D8B",
    sortKey: 11,
  },
  {
    title: "Sonstige Veranstaltung",
    shortTitle: "ÜD",
    description: "",
    color: "#9E9E9E",
    sortKey: 13,
  },
];
